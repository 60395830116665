import React from 'react';
import Header from '../Header/Header';
import './App.scss';
import {navData} from '../../inforamtion/headerInfo';
import BgVideo from '../BgVideo/BgVideo';
import Footer from '../Footer/Footer';
import CircularChart from '../CircularChart/CircularChart';
import SelectGroup from '../SelectGroup/SelectGroup';
import Accordeon from '../Accordeon/Accordeon';
import Alert from '../Alert/Alert';
import RequestModal from '../RequestModal/RequestModal';
class App extends React.Component {
  state = {
    isHeaderDark: false,
    isChartAnimationActive: false,
    alert: {
      isActive: false,
      title: '',
      text: ''
    },
    isModalOpen: false,
    currentService: 'Select your service'
  }

  changeTheme = (value) => {
    this.setState({themeType: value})
  }

  handleSelectChange = (value) => {    
    this.setState(({currentService}) => ({currentService: value}))
  }

  listenScrollEvent = e => {
    const sectionHeight = document.querySelector('.section-first').offsetHeight;
    
    if (window.scrollY > sectionHeight - 60) {
        this.setState({isHeaderDark: true});
    } else {
        this.setState({isHeaderDark: false});
    }
  }
  activeAlert = ({title, text}) => {
    this.setState(() => ({alert: {isActive: true, title, text}}))
    setTimeout(() => {
      this.setState(() => ({alert: {isActive: false, title, text}}))
    }, 4000)
  }

  setModalDisplay = action => {
    this.setState(({currentService}) => ({currentService: "Select your service"}))
    this.setState(isModalOpen => ({isModalOpen: action}))
  }

  UNSAFE_componentWillMount() {
      window.addEventListener('scroll', this.listenScrollEvent);
  }
  componentWillUnmount() {
      window.removeEventListener('scroll', this.listenScrollEvent);
  }

  render() {
    return (
      <div className="app" name="home">
        <RequestModal 
          currentService={this.state.currentService} 
          onSelectChange={this.handleSelectChange}
          activeAlert={this.activeAlert}
          isModalOpen={this.state.isModalOpen}
          onClose={this.setModalDisplay}
        />
        {this.state.alert.isActive && <Alert content={this.state.alert}>Thank you!</Alert>}
        <Header data={navData} isHeaderDark={this.state.isHeaderDark} handelModalClick={this.setModalDisplay} />
        
        <section className="section-first">
          <BgVideo onClick={this.changeTheme} />
          <div className="section-first-content">
            <h1 className="main-title">EXPERTS IN THE WORLD OF MARINE AND CORPORATE TRAVEL</h1>
            <h3 className="subtitle">We solve any tasks in the travel industry</h3>
          </div>
        </section>

        <section name="services" className="section-three">
          <div className="container">
            <SelectGroup onClick={this.handleSelectChange} setModalDisplay={this.setModalDisplay} />
          </div>
        </section>

        <section name="about us" className="company-information">
          <div className="container company-information-wrapper in-view-parent">
              <CircularChart value="97%" text="CLIENT RETENTION" />
              <CircularChart value={new Date().getFullYear() - 2013} text="YEARS OF EXPERIENCE" />
              <CircularChart value="96%" text="CLIENT SATISFACTION" />
              <CircularChart value="24/7" text="GLOBAL SUPPORT" />
          </div>
        </section>

        <section  className="section-accord">
          <Accordeon activeAlert={this.activeAlert} />
        </section>
        <Footer />
      </div>
    );
  }
}

export default App;
